import React, { useEffect } from "react";
import { useState } from "react";
import "./App.css";
import SplashScreen from "./Components/SplashScreen/SplashScreen";
import Router from "./Routes/Router";

function App() {
  // const ref = firebase.firestore().collection("Master List");

  // function getData() {
  //   const d = [];
  //   ref.onSnapshot((querySnapshot) => {
  //     querySnapshot.forEach((doc) => {
  //       d.push(doc.data());
  //     });
  //   });
  // }

  // useEffect(() => {
  //   getData();
  // }, []);
  const [flag, setFlag] = useState(1);

  const setSplash = () => {
    setFlag(0);
  };

  useEffect(() => {
    setTimeout(setSplash, 4000);
  }, []);

  return (
    <div className="App">
      {flag ? <SplashScreen /> : <Router />}
      {/* <SplashScreen/> */}
    </div>
  );
}

export default App;
