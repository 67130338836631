import React from "react";

import "./TablePage.scss";
import save from "../../Assets/Save.png";

import { useNavigate } from "react-router-dom";

import Table3 from "../../Components/Table/Table3";

function TablePage3(props) {
  console.log(props, "props");
  const navigate = useNavigate();

  return (
    <div className="table-page">
      <div className="btn-div flex items-center">
        <button
          className="back-btn"
          onClick={() => navigate("/version3/showList")}
        >
          <i className="bi bi-arrow-left-short"></i>
        </button>
        <button
          onClick={() => navigate("/master-list")}
          style={{
            background: "#0762A3",
            padding: "10px 20px",
            borderRadius: "8px",
            color: "white",
            marginRight: "10px",
          }}
        >
          + Add Provider
        </button>
        <button>
          <img
            src={save}
            alt=""
            style={{ width: "20px", paddingBottom: "5px" }}
          />
          <p
            style={{
              paddingBottom: "10px",
              color: "#0762A3",
              fontWeight: "600",
            }}
          >
            Save
          </p>
        </button>
      </div>
      <div className="table">
        {props.data?.length !== 0 && (
          <Table3 data={props.data} customDate={props.customDate} />
        )}
      </div>
      <div style={{ minHeight: "100px" }}></div>
    </div>
  );
}

export default TablePage3;
