import React, { useEffect } from "react";
import { useState } from "react";
import "./Table.scss";

function Table3({
  data,

  customDate,
}) {
  var headers = [
    "Invoice No",
    "Customer",
    "Invoice Date",
    "Due Date",
    "Terms",
    "Location",
    "Class",
    "Service Date",
    "Taxable",
    "Item Quantity",
    "Rate",
    "Item Amount",
  ];

  const [total, setTotal] = useState();

  const ChangeCustomDate = async () => {
    for (const i of data) {
      i["Invoice Date"] = customDate;
    }
  };

  useEffect(() => {
    ChangeCustomDate();
  }, []);

  return (
    <table className="table">
      <thead>
        <tr>
          {headers
            ? headers.map((data, key) => {
                return (
                  <th scope="col" key={key}>
                    <p>{data}</p>
                  </th>
                );
              })
            : null}
        </tr>
      </thead>
      <tbody>
        {data && data.length != 0
          ? data.map((dat, key) => {
              return (
                <tr className={key % 2 != 0 ? "color" : "non-color"} key={key}>
                  <td>
                    <input
                      type="text"
                      defaultValue={dat.InvoiceNo}
                      onChange={(e) => {
                        dat.InvoiceNo = e.target.value;
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={dat.Customer}
                      onChange={(e) => {
                        dat.Customer = e.target.value;
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={dat.InvoiceDate}
                      onChange={(e) => {
                        dat.InvoiceDate = e.target.value;
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={dat.DueDate}
                      onChange={(e) => {
                        dat.DueDate = e.target.value;
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={dat.Terms}
                      onChange={(e) => {
                        dat.Terms = e.target.value;
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={dat.Location}
                      onChange={(e) => {
                        dat.Location = e.target.value;
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={dat.Class}
                      onChange={(e) => {
                        dat.Class = e.target.value;
                      }}
                    />
                  </td>

                  <td>
                    <input
                      type="text"
                      defaultValue={dat.ServiceDate}
                      onChange={(e) => {
                        dat.ServiceDate = e.target.value;
                      }}
                    />
                  </td>

                  <td>
                    <input
                      type="text"
                      defaultValue={dat.Taxable}
                      onChange={(e) => {
                        dat.Taxable = e.target.value;
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={dat.ItemQuantity}
                      onChange={(e) => {
                        dat.ItemQuantity = e.target.value;
                        dat.Amount =
                          parseInt(dat.Rate) * parseInt(e.target.value);
                        setTotal((prev) => !prev);
                      }}
                    />
                  </td>

                  <td>
                    <input
                      type="text"
                      defaultValue={dat.Rate}
                      onChange={(e) => {
                        dat.Rate = e.target.value;
                        dat.Amount =
                          parseInt(dat.ItemQuantity) * parseInt(e.target.value);
                        setTotal((prev) => !prev);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={dat.Amount}
                      value={dat.Amount}
                      onChange={(e) => {
                        dat.Amount = e.target.value;
                        setTotal((prev) => !prev);
                      }}
                    />
                  </td>
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );
}

export default Table3;
