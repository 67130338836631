import React, { useState } from "react";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import "./loader.scss";

const override = css`
  display: block;
  margin: 0 auto;R
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function Loader({ loading }) {
  let [color, setColor] = useState("#0762A3");

  return (
    <div>
      {loading && (
        <div className="loader">
          {/* <div className="circle"> */}

          {/* <video src={loaderVid} loop autoPlay muted></video> */}
          {/* {/* <img src={loaderIcon} className="loaderimg" />  */}
          {/* </div> */}
          {/* <ClipLoader color={color} loading={true} css={override} size={150} /> */}
          {/* <BeatLoader color={color} loading={true} css={override} size={150}/> */}

          <div style={{ display: "flex" }}>
            <BeatLoader color={color} css={override} size={25} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Loader;
