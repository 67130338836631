import React, { useEffect, useState } from "react";
import "./FileShow.scss";
import FileSaver from "file-saver";
import JSZip from "jszip";
import Edit from "../../Assets/Edit.png";
import Save from "../../Assets/Download.png";
import Delete from "../../Assets/Delete.png";

import { useNavigate } from "react-router-dom";

import * as XLSX from "xlsx-js-style";

import editIcon from "../../Assets/edit_icon.png";
import Loader from "../Loader/Loader";

function FileShow3({
  setData,
  fileName,
  fileData,
  setFileName,
  errorList,
  setNameMasterCode,
  openModal,
  resetUpload,
}) {
  const navigate = useNavigate();

  // ** State managements
  const [selectAll, setSelectAll] = useState(false);
  const [fileDisplay, setFileDisplay] = useState(fileName);
  const [fileNum, setFileNum] = useState(0);
  const [deleteWarn, setDeleteWarn] = useState(0);
  const [loader, setLoader] = useState(false);

  const getUserRole = () => {
    return localStorage.getItem("role") === "admin" ? true : false;
  };

  const EditFile = (name) => {
    setData(fileData.get(name));
    setNameMasterCode(name);
  };

  const deleteAll = () => {
    setFileDisplay([]);
    setFileNum("0");
    localStorage.removeItem("version3");
    navigate("/version3/upload");
  };

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const downloadAll = async () => {
    setLoader(true);
    const date = new Date();
    setDeleteWarn(0);
    var zip = new JSZip();
    var iterate = 0;
    for (var a of fileName) {
      const data = await npgZip(fileData.get(a), a, iterate);
      let wbout = XLSX.write(data, {
        bookType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      zip.file(a + makeid(5) + "_.xlsx", wbout, { binary: true });
      iterate++;
    }

    await zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, `data_${date.toLocaleDateString()}.zip`);
    });
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  function formatCurrency(amount, currencySymbol = "$") {
    amount = amount ? amount : 0;
    // Convert the number to a string and use the toLocaleString method
    return (
      currencySymbol +
      amount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }

  // Utility function to transform data
  const transformData = (data) => {
    const sortedData = data.sort((a, b) => {
      if (a["Practitioner ID"] < b["Practitioner ID"]) return -1;
      if (a["Practitioner ID"] > b["Practitioner ID"]) return 1;
      return 0;
    });

    return sortedData.map((entry) => ({
      "Invoice No": entry["InvoiceNo"],
      Customer: entry["Customer"],
      "Invoice Date": entry["InvoiceDate"],
      "Due Date": entry["DueDate"],
      Terms: entry["Terms"],
      Location: entry["Location"],
      Class: entry["Class"],
      "Agency Id": entry["Agency Id"],
      "Agency Name": entry["Agency Name"],
      "Provider Id": entry["Provider ID"],
      "Practitioner Id": entry["Practitioner ID"],
      "Practitioner Name": entry["Practitioner Name"],
      "Service Date": entry["ServiceDate"],
      Taxable: entry["Taxable"],
      "Item Quantity": entry["ItemQuantity"],
      "Total Hours Worked": entry["Total Hours Worked"],
      Rate: formatCurrency(entry["Rate"]),
      "Item Amount": formatCurrency(entry["Amount"]),
    }));
  };

  const exportWorkbook = async (data, fileName) => {
    if (!data.length) {
      console.error("No data to export.");
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(data);

    const headers = Object.keys(data[0]);

    headers.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index }); // header row, column based on index

      if (!worksheet[cellAddress]) {
        worksheet[cellAddress] = {};
      }

      worksheet[cellAddress].s = {
        font: {
          bold: true,
          name: "Arial",
          sz: 11,
        },

        fill: {
          fgColor: { rgb: "F2CEEF" },
        },
        alignment: { horizontal: "left" },
      };

      if (!worksheet["!cols"]) {
        worksheet["!cols"] = [];
      }

      worksheet["!cols"][index] = { wch: header.length + 5 };
    });

    const rowCount = data.length;
    const columnCount = headers.length;

    for (let row = 1; row <= rowCount; row++) {
      for (let col = 0; col < columnCount; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });

        if (!worksheet[cellAddress].s) {
          worksheet[cellAddress].s = {};
        }
        worksheet[cellAddress].s = {
          font: {
            name: "Arial",
            sz: 11,
          },
          alignment: { horizontal: "left" },
        };
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, fileName);
    console.log(`Data exported successfully to ${fileName}`);
  };

  const downloadAllinASingleFile = async () => {
    setLoader(true);
    const localData = [];

    fileName.forEach((file) => {
      // if (errorList.includes(file)) {
      //   console.warn(`Skipping file: ${file}`);
      //   return;
      // }

      const fileDataEntry = fileData.get(file);
      if (!fileDataEntry || !Array.isArray(fileDataEntry.data)) {
        console.error(`Invalid data for file: ${file}`);
        return;
      }

      const transformedData = transformData(fileDataEntry.data);
      localData.push(...transformedData);
    });

    await exportWorkbook(localData, "data.xlsx");
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  // Function to create a workbook for a given JSON array
  const npgZip = async (jsonArray) => {
    const transformedData = transformData(jsonArray.data);
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet);
    return workbook;
  };

  // Function to download a specific JSON array as a file
  const download = async (jsonArray, name) => {
    setLoader(true);
    const transformedData = transformData(jsonArray?.data || []);
    await exportWorkbook(transformedData, `${name}.xlsx`);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const DeleteFile = async (name) => {
    var filteredArray = fileDisplay.filter(function (e) {
      return e !== name;
    });
    setFileDisplay(filteredArray);
    setFileNum(filteredArray.length);
    setFileName(filteredArray);
  };

  useEffect(() => {
    setFileDisplay(fileName);
    setFileNum(fileName.length);
  }, [fileName]);

  return (
    <>
      <Loader loading={loader} />
      <div className="file-show">
        {deleteWarn ? (
          <div className="header deleteWarn">
            <div className="warning-header">
              <div
                className="back-btn"
                onClick={() => {
                  setDeleteWarn(0);
                }}
              >
                <i className="bi bi-arrow-left-short"></i>
              </div>

              <div>Are you sure you want to delete all your files?</div>
            </div>

            <div className="controls">
              <div className="box-c">
                <div className="box">
                  <img
                    src={Delete}
                    alt="delete all"
                    onClick={() => {
                      deleteAll();
                      setDeleteWarn(0);
                      setSelectAll((prev) => !prev);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="header">
            <div
              className="back-btn"
              onClick={() => navigate("/version3/upload")}
            >
              <i className="bi bi-arrow-left-short"></i>
            </div>

            <h6>{fileNum > 0 ? fileNum : null} Files</h6>

            <div className="controls-c">
              <div
                className="input-group"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {getUserRole() ? (
                  <div className="box-c">
                    <div className="box">
                      <i
                        className="bi bi-arrow-counterclockwise"
                        onClick={() => {
                          resetUpload();
                        }}
                      ></i>
                      {/* <i
                    className="bi bi-box-arrow-up"
                    onClick={() => {
                      openModal();
                    }}
                  ></i> */}
                    </div>
                    <p>Re Upload</p>
                  </div>
                ) : null}

                <div className="box-c">
                  <div className="box">
                    <img
                      src={editIcon}
                      onClick={() => {
                        openModal();
                      }}
                    />
                    {/* <i
                    className="bi bi-box-arrow-up"
                    onClick={() => {
                      openModal();
                    }}
                  ></i> */}
                  </div>
                  <p>Edit Invoice</p>
                </div>
                <div className="box-c">
                  <div
                    className="box"
                    onClick={() => setSelectAll((prev) => !prev)}
                  >
                    {/* {selectAll ? <img src={Check} alt="" srcset="" /> : null} */}
                  </div>
                  {selectAll ? <p> Deselect</p> : <p>Select All</p>}
                </div>
              </div>
              {selectAll ? (
                <div className="controls">
                  <div className="box-c">
                    <div className="box">
                      <img
                        src={Save}
                        alt="save all"
                        onClick={() => downloadAll()}
                      />
                    </div>
                    <p>Zip</p>
                  </div>
                  <div className="box-c">
                    <div className="box">
                      <img
                        src={Save}
                        alt="save all"
                        onClick={() => downloadAllinASingleFile()}
                      />
                    </div>
                    <p>File</p>
                  </div>
                  <div className="box-c" onClick={() => setDeleteWarn(1)}>
                    <div className="box">
                      <img src={Delete} alt="delete all" />
                    </div>
                    <p>Delete</p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
        <div className="body">
          {console.log(fileDisplay, "fileDisplay")}
          {fileDisplay
            ? fileDisplay.map((data, key) => {
                return (
                  <div className="file-div" key={key + data}>
                    <div className={selectAll ? "details selected" : "details"}>
                      <p>{data}</p>

                      {errorList && errorList.includes(data) ? (
                        <div className="error-div">
                          <p>
                            <i className="bi bi-info-circle"></i> This File
                            needs Attention.
                          </p>
                        </div>
                      ) : null}
                      <div className="icons">
                        <img
                          src={Edit}
                          alt=""
                          onClick={() => {
                            EditFile(data);
                            navigate(`/version3/showTable`);
                          }}
                        />
                        <img
                          src={Save}
                          alt=""
                          onClick={() => {
                            download(fileData.get(data), data, key);
                          }}
                        />
                        <img
                          src={Delete}
                          alt=""
                          onClick={() => {
                            DeleteFile(data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
}

export default FileShow3;
