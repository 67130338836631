import React, { useState } from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Upload from "../Components/Upload/Upload";

import UploadedFiles from "../Pages/UploadedFiles/UploadedFiles";
import Login from "../Pages/Login/Login";
import Register from "../Pages/Register/Register";
import Home from "../Pages/Home/Home";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Router.scss";
import Protected from "../Protected/Protected";
import NotFound from "../Pages/NotFound/NotFound";
import MasterList from "../Pages/MasterList/MasterList";
import FeatureSoon from "../Pages/FeatureSoon/FeatureSoon";
import UserList from "../Pages/UserList/UserList";
import Forget from "../Pages/Forget/Forget";
import Home2 from "../Pages/Home/Home2";
import Home3 from "../Pages/Home/Home3";

function Router() {
  const [selected, setSelected] = useState("home");
  const location = useLocation();

  return (
    <div className="Router">
      {location &&
      (location.pathname === "/dashboard/upload" ||
        location.pathname === "/dashboard/showList" ||
        location.pathname === "/dashboard/showTable" ||
        location.pathname === "/new/upload" ||
        location.pathname === "/version3/upload" ||
        location.pathname === "/version3/showList" ||
        location.pathname === "/version3/showTable" ||
        location.pathname === "/new/showList" ||
        location.pathname === "/new/showTable" ||
        location.pathname === "/master-list" ||
        location.pathname === "/profile" ||
        location.pathname === "/user-list" ||
        location.pathname === "/setting") ? (
        <div className="sidebarWrapper">
          <Sidebar selected={selected} setSelected={setSelected} />
        </div>
      ) : null}

      <div className="pageWrapper">
        <Routes>
          <Route path="upload" element={<Upload />}></Route>
          <Route path="version3/:id" element={<Home3 />}></Route>
          <Route path="new/:id" element={<Home2 />}></Route>
          <Route path="uploaded" element={<UploadedFiles />}></Route>
          <Route path="login" element={<Login />}></Route>
          <Route path="register" element={<Register />}></Route>
          <Route path="forget" element={<Forget />}></Route>
          <Route
            path="dashboard/:id"
            element={<Protected cmp={Home} />}
          ></Route>
          <Route
            path="master-list"
            element={<Protected cmp={MasterList} />}
          ></Route>
          <Route
            path="user-list"
            element={<Protected cmp={UserList} />}
          ></Route>
          <Route
            path="profile"
            element={<Protected cmp={FeatureSoon} />}
          ></Route>
          <Route
            path="setting"
            element={<Protected cmp={FeatureSoon} />}
          ></Route>

          <Route
            path="/"
            element={<Navigate replace to="dashboard/upload" />}
          ></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </div>
    </div>
  );
}

export default Router;
